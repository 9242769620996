import { MutationTree } from 'vuex';
import { CosplayItem, State } from '@/store/state';

// eslint-disable-next-line no-shadow
export enum MutationType {
  CreateItem = 'CREATE_ITEM',
  SetItems = 'SET_ITEMS',
  CompleteItem = 'COMPLETE_ITEM',
  SetLoading = 'SET_LOADING',
  SetSaving = 'SET_SAVING',
}

export type Mutations = {
  [MutationType.CreateItem](state: State, item: CosplayItem): void
  [MutationType.SetItems](state: State, items: Map<string, CosplayItem>): void
  [MutationType.CompleteItem](
    state: State,
    item: Partial<CosplayItem> & { id: number }
  ): void
  [MutationType.SetLoading](state: State, value: boolean): void
  [MutationType.SetSaving](state: State, value: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationType.CreateItem](state, item) {
    const map = new Map(state.items);
    map.delete(item.id);
    state.items = map;
  },
  [MutationType.SetItems](state, items) {
    state.items = items;
  },
  [MutationType.CompleteItem](state, item) {
    const map = new Map(state.items);
    const itemToChange = map.get(item.id);
    if (itemToChange === undefined) return;
    itemToChange.completed = true;
  },
  [MutationType.SetLoading](state, value) {
    state.loading = value;
  },
  [MutationType.SetSaving](state, value) {
    state.saving = value;
  },
};
