<template>
  <div class="table-header">
    <div class="long">Название</div>
    <div>Стоимость</div>
    <div>Статус</div>
  </div>

  <CosplayItem v-for="item in items" :key="item" v-bind="item" />
  <NewCosplayItem ref="newItem"/>

  <div class="bottom-button">
    <svg @click="onCreateButton()" class="w-16 h-16 round bg-indigo-2"
         fill="none" stroke="currentColor" viewBox="0 0 24 24"
         xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
    </svg>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import CosplayItem from '@/components/CosplayItem.vue';
import NewCosplayItem from '@/components/NewCosplayItem.vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';

export default defineComponent({
  components: { NewCosplayItem, CosplayItem },
  data() {
    const store = useStore();
    const items = computed(() => store.state.items.values());
    return {
      items,
    };
  },

  setup() {
    const newItem = ref();
    const onCreateButton = () => {
      newItem.value.onActivate();
    };
    return { newItem, onCreateButton };
  },

  created() {
    const store = useStore();
    store.dispatch(ActionTypes.GetAllCosplayItems);
  },
});
</script>

<style lang="stylus" scoped>
.table-header
  display flex
  font-size 1.2em

  > div
    display flex
    justify-content: space-around;
    flex 1

  > div.long
    flex 4

.w-16
  width 64px

.h-16
  height 64px

.round
  border-radius 50%

.bottom-button
  display flex
  justify-content: flex-end;
  margin 16px
</style>
