<template >
  <div class="new-item" v-if="visible">
  <div class="item">
      <input type="text" v-model="name" class="name"/>
      <input v-model="cost" class="cost" type="number"/>
  </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { CosplayItem } from '@/store/state';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';
import { v4 as uuidV4 } from 'uuid';

export default defineComponent({
  data() {
    return { enabled: false };
  },
  setup() {
    const name = ref();
    const cost = ref();
    const visible = ref();

    const store = useStore();

    const createNewItem = () => {
      if (name.value != null && name.value === '') return;
      if (cost.value === '') {
        cost.value = null;
      }
      const item: CosplayItem = {
        id: uuidV4(),
        name: name.value,
        cost: cost.value,
        completed: false,
      };

      name.value = '';
      cost.value = '';
      store.dispatch(ActionTypes.AddCosplayItem, item);
      visible.value = false;
    };

    const onActivate = () => {
      if (!visible.value) {
        visible.value = true;
        return;
      }
      createNewItem();
    };

    return {
      onActivate, name, cost, visible,
    };
  },
});
</script>

<style lang="stylus" scoped>

.item
  display flex

input.name
  flex 4

input.cost
  flex 1

input:focus-visible
  outline none
  box-shadow: 0 0 10px 0 #3730a3;

</style>
