import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationType } from '@/store/mutations';
import { CosplayItem, State } from '@/store/state';

const COSPLAY_ITEMS_KEY = 'COSPLAY_ITEMS';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
  GetAllCosplayItems = 'GET_ITEMS',
  AddCosplayItem = 'ADD_COSPLAY_ITEM',
  UpdateCosplayItem = 'UPDATE_COSPLAY_ITEM',
  RemoveCosplayItem = 'REMOVE_COSPLAY_ITEM',
}

type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetAllCosplayItems](context: ActionAugments): void
  [ActionTypes.AddCosplayItem](context: ActionAugments, item: CosplayItem): void
  [ActionTypes.UpdateCosplayItem](context: ActionAugments, item: CosplayItem): void
  [ActionTypes.RemoveCosplayItem](context: ActionAugments, item: CosplayItem): void
}

function replacer(key: any, value: any[]) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  }
  return value;
}

function reviver(
  key: string,
  value: { dataType: string; value: Iterable<readonly [unknown, unknown]>; } | null,
) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

function setCosplayItems(storage: Storage, items: Map<string, CosplayItem>): void {
  storage.setItem(COSPLAY_ITEMS_KEY, JSON.stringify(items, replacer));
}

function getCosplayItems(storage: Storage): Map<string, CosplayItem> {
  const items = storage.getItem(COSPLAY_ITEMS_KEY);
  let actualItems: Map<string, CosplayItem>;
  if (items == null) {
    actualItems = new Map<string, CosplayItem>();
    setCosplayItems(storage, actualItems);
  } else {
    actualItems = JSON.parse(items, reviver);
  }
  return actualItems;
}

export const actions: ActionTree<State, State> & Actions = {
  async [ActionTypes.GetAllCosplayItems]({ commit }) {
    commit(MutationType.SetLoading, true);

    const { localStorage } = window;
    const cosplayItems = getCosplayItems(localStorage);

    commit(MutationType.SetLoading, false);
    commit(MutationType.SetItems, cosplayItems);
  },
  async [ActionTypes.AddCosplayItem]({ commit }, item: CosplayItem) {
    commit(MutationType.SetSaving, true);

    const { localStorage } = window;
    const cosplayItems = getCosplayItems(localStorage);
    cosplayItems.set(item.id, item);

    setCosplayItems(localStorage, cosplayItems);
    commit(MutationType.SetItems, cosplayItems);
    commit(MutationType.SetSaving, false);
  },
  async [ActionTypes.UpdateCosplayItem]({ commit }, item: CosplayItem) {
    commit(MutationType.SetSaving, true);

    const { localStorage } = window;
    const cosplayItems = getCosplayItems(localStorage);
    const cosplayItem = cosplayItems.get(item.id);
    if (cosplayItem === undefined) {
      commit(MutationType.SetSaving, false);
      console.error('Unknown element');
      return;
    }
    cosplayItems.set(item.id, item);
    setCosplayItems(localStorage, cosplayItems);
    commit(MutationType.SetItems, cosplayItems);
    commit(MutationType.SetSaving, false);
  },
  async [ActionTypes.RemoveCosplayItem]({ commit }, item: CosplayItem) {
    commit(MutationType.SetSaving, true);

    const { localStorage } = window;
    const cosplayItems = getCosplayItems(localStorage);
    cosplayItems.delete(item.id);
    setCosplayItems(localStorage, cosplayItems);
    commit(MutationType.SetItems, cosplayItems);
    commit(MutationType.SetSaving, false);
  },
};
