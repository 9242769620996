export type Note = {
  id: string,
  name: string,
  content: string,
}

export type ItemImage = {
  id: string,
  name: string,
  content: string
}

export type CosplayItem = {
  id: string,
  name: string,
  cost?: number,
  completed: boolean,
  notes?: Note[],
  images?: ItemImage[],
  previewImageIndex?: number,
}

export type State = {
  loading: boolean,
  saving: boolean,
  items: Map<string, CosplayItem>,
}

export const state: State = {
  loading: false,
  saving: false,
  items: new Map<string, CosplayItem>(),
};
