
import { defineComponent } from 'vue';
import router from '@/router';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: false,
    },
    completed: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const navigate = () => router.push({ name: 'Item', params: { itemId: props.id } });
    return { navigate };
  },
});
