<template>
  <div class="item" @click="navigate()">
<!--    <img class="item-images" src="https://www.tizgroup.ru/images/photo-tema-2018/ACS/pariki/avatar_parik_markizy_pompadu0r_1.jpg"/>-->
    <div class="content">
      <div class="name">{{ name }}</div>
      <div class="icons">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
      </div>
    </div>
    <div class="cost"> <p v-if="cost != null && !isNaN(cost)">{{ cost }}₽</p>
    </div>
    <div class="status">
      <svg class="w-6 h-6" v-if="completed" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
      <svg class="w-6 h-6 danger-red" v-if="!completed" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import router from '@/router';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: false,
    },
    completed: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const navigate = () => router.push({ name: 'Item', params: { itemId: props.id } });
    return { navigate };
  },
});
</script>

<style lang="stylus" scoped>
.item
  display flex

.item > div
  flex 1

.item > .content
  flex 4
  margin 8px

.cost
  display flex
  font-size 1.3em

.status
  display flex
  align-items center
  justify-content center
  > svg
    width 48px
    height 48px

.name
  font-size 1.4em

.item-image
  flex: 1
  min-width: 0
  min-height 0

.danger-red
  color: #b30e0e

.color-good
  color: #2e8e2e
</style>
