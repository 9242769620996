
import { computed, defineComponent, ref } from 'vue';
import CosplayItem from '@/components/CosplayItem.vue';
import NewCosplayItem from '@/components/NewCosplayItem.vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/actions';

export default defineComponent({
  components: { NewCosplayItem, CosplayItem },
  data() {
    const store = useStore();
    const items = computed(() => store.state.items.values());
    return {
      items,
    };
  },

  setup() {
    const newItem = ref();
    const onCreateButton = () => {
      newItem.value.onActivate();
    };
    return { newItem, onCreateButton };
  },

  created() {
    const store = useStore();
    store.dispatch(ActionTypes.GetAllCosplayItems);
  },
});
