<template>
  <div class="nav">
    <router-link to="/" class="sub-nav-elem">
      <div>Elements</div></router-link>
    <router-link to="/about" class="sub-nav-elem"><div>Todo</div></router-link>
    <router-link to="/" class="sub-nav-elem"><div>Notes</div></router-link>
  </div>

  <router-view/>
</template>

<style lang="stylus">

gray-1 = #1F2937
gray-2 = #111827
indigo-1 = #3730A3
indigo-2 = #312e81

.nav
  display flex
  height: 64px
  a
    flex 1
    background-color indigo-1
    text-decoration inherit
    display flex
    align-content center
    justify-content center
    > div
      margin auto
  a.router-link-active
    background-color indigo-2

body
  background-color #121212
  color #ccc
  margin 0
  padding 8

a:visited
  color inherit

.flex
  display flex

.flex-1
  flex 1

.text-center
  text-align: center

.vertical-align-middle
  vertical-align: middle;

.bg-gray-1
  background-color gray-1
.bg-gray-2
  background-color gray-2

.bg-indigo-1
  background-color indigo-1
.bg-indigo-2
  background-color indigo-2

.w-6
  width 24px
.h-6
  height 24px

input
  background-color: indigo-1
  border: transparent
  margin: 8px
  color white
  min-width: 8px

</style>
